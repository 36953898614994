.test{
  color: brown;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.name {
  font-size: 7em;
}

html {
  background-color: black;
  margin: 0px;
  padding: 0px;
}

.href-links {
  text-decoration:none;
  color: inherit;
}

#show:hover + #hidden {
  display: block;
}

.grow { 
  transition: all .2s ease-in-out; 
  }
  
  .grow:hover { 
  transform: scale(1.1); 
  }

.body-font {
  font-family: 'Montserrat', sans-serif;
}

.about {
  height: 100vh;
  display: flex;
  align-items: center;
}

.flexbox-container {
  display: flex;
  justify-content: center;  
}

.devicons {
  font-size: 2.5rem;
  padding: 20px 0px 0px 0px;
}

.devicons i {
  padding: 5px;
}

.devicons-about {
  font-size: 4rem;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.devicon-div {
  border: 1px solid #ffffff;
  margin: 5px;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.github-icon {
  font-size: 3rem;
}

.interests-icon-container
{
  padding: 10px;
}

.interests-icon {
  width: 150px;
}

.flexbox-item {
width: 50%;
margin:10px;
background-color: rgb(255, 255, 255);
}

/* quick reset and base styles */
* {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  font-family: helvetica, arial, sans-serif;
}

/* relevant styles */
.img__wrap {
  position: relative;
  height: 320px;
  width: 320px;
}

.img__description_layer {
  position:absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(49, 48, 48, 0.849);
  color: rgb(255, 255, 255);
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 205px;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .2s;
  transform: translateY(1em);
  font-size: 1rem;
}

.img__wrap:hover .img__description {
  transform: translateY(0);
}

.blog-font {
  font-family: 'Montserrat', sans-serif;
}

.blog-image {
  width:500px;
  height: auto;
  border: solid white 2px;
  padding: 5px;  
}

.blog-image-figure {
  float: right;
  margin: 10px;
  text-align: right;
}

/* Experience section container */

.experience {
  height: 100vh;
}

.exp-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exp-column {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.exp-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 10px;
}

.exp-part {
  flex: 0 0 auto;
  width: auto;
  padding: 20px;
  max-width: 45%;
}

.languages
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.languages-container {
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 400px;
  height: 400px;
  padding: 5%;
}

.languages-header
{
  padding-top: 20px;
  padding-bottom: 5px;
  font-weight: bold;
}

.evotix
{
  text-align: left;
}

.student
{
  text-align: right;
}

.devicons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Align items at the top */
  max-width: 300px;
}

.hero-image-container {
  align-items: center;
  padding:  30px 0 20px 0;
}

.interests-header-container
{
  text-align: center;
}

.interests {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interests-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
}

.hero-image
{
  width: 200px; /* Set this value to what you want the diameter to be */
  height: 200px; /* Ensure the height and width are equal */
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid black;
}

.azure {
  background-color: black;
}

.contact {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-icons {
  display: flex;
  align-items: center;
}

.contact-icons a i {
  font-size: 3rem;
  padding: 20px;
  color: black;
  display: inline-block;
  vertical-align: middle;
}

.navbar-container
{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.banner-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




@import '~bulma/css/bulma.css';